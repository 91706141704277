import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.formItem,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          rules: _ctx.rules,
          autoComplete: "off",
          ref: "refDentistTeamForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "名称",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formItem.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formItem.name) = $event)),
                  style: {"width":"200px"},
                  placeholder: "输入团队名称"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "负责人",
              name: "principal"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formItem.principal,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formItem.principal) = $event)),
                  options: _ctx.selectorOptions.principalOptions,
                  placeholder: "输入负责人姓名",
                  "show-search": "",
                  style: {"width":"200px"},
                  onSearch: _ctx.onPrincipalSearch
                }, null, 8, ["value", "options", "onSearch"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "成员",
              name: "memberIds"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formItem.memberIds,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formItem.memberIds) = $event)),
                  options: _ctx.selectorOptions.memberOptions,
                  mode: "multiple",
                  "show-search": "",
                  placeholder: "选择团队成员",
                  onSearch: _ctx.onMemberSearch
                }, null, 8, ["value", "options", "onSearch"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "label-col", "wrapper-col", "rules"])
      ]),
      _: 1
    })
  ]))
}