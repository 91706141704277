
import {Rule} from "ant-design-vue/lib/form";
import {
  defineComponent,
  watch,
  reactive,
  ref,
  toRefs,
  UnwrapRef, onMounted,
} from "vue";
import {createDentistTeam, getDentistTeam, updateDentistTeam} from "@/api/dentist_team";
import {searchDentist} from "@/api/doctor"
import STATUS from "@/components/model/enum";

interface FormItem {
  id: string;
  name: string;
  principal: any;
  memberIds: any[];
}


interface SelectorOptions {
  principalOptions: any[];
  memberOptions: any[];
}

export default defineComponent({
  name: "DentistTeamForm",
  emits: ["onCancel"],
  props: ["type", "dentistTeamId", "dentistTeam"],

  setup: function (props, context) {

    const data = reactive({
      loading: false,
    });


    let formItem: UnwrapRef<FormItem> = reactive({
      id: "",
      name: "",
      operation: 'Create',
      principal: "",
      memberIds: []
    });

    let selectorOptions: UnwrapRef<SelectorOptions> = reactive({
      principalOptions: [],
      memberOptions: []
    });


    // eslint-disable-next-line @typescript-eslint/no-unused-vars


    const rules: Record<string, Rule[]> = {
      name: [
        {
          required: true,
          trigger: "change",
          validator: (_rule, value) => {
            if (!value) {
              return Promise.reject("请输入团队名称!");
            }
            return Promise.resolve();
          },
        },
      ],
      principal: [
        {
          required: false,
          trigger: "change",
          message:'请选择负责人'
        },
      ],
      memberIds: [
        {
          required: false,
          trigger: "change",
          message:'请选择成员'
        },
      ],
    };


    const handleChange = (value: string) => {
      console.log(value);
    };

    const refDentistTeamForm = ref()
    const onSubmit = async () => {
      console.log("....")
      refDentistTeamForm.value.validate().then(() => {
        if (props.type == 0) {
          add(formItem)
        } else {
          console.log(formItem);
          let parameters = {id: formItem.id, operation: 'Update', members: formItem.memberIds, principal: formItem.principal.value?formItem.principal.value:null,name: formItem.name}
          update(parameters)
        }
      }).catch(() => {
        console.log("....")
        // context.emit("onCancel", false);
      })
    };

    const add = (option: any) => {
      createDentistTeam(option)
        .then(() => {
          context.emit("onCancel", true);
        })
        .catch(() => {
          context.emit("onCancel", false);
        });
    }
    const update = (option: any) => {
      updateDentistTeam({
        name:option.name,
        members:option.members,
        principal:option.principal,
        id:option.id,
        operation:option.operation
      }).then(() => {
        context.emit("onCancel", true);
      }).catch(() => {
        context.emit("onCancel", false);
      });
    }

    const cleanFormItem = () => {
      formItem.id = ''
      formItem.name = ''
      formItem.memberIds = []
      formItem.principal = null
    }

    const handleCancel = () => {
      cleanFormItem()
      context.emit("onCancel");
    };

    const onPrincipalSearch = (val: any) => {
      let fun = (option: any) => {
        selectorOptions.principalOptions = option;
      }
      searchDentistByKeyword(val, fun);
    }

    const searchDentistByKeyword = (keyword: any, func: any) => {
      let parameters = {page: {pageSize: 20, currPage: 1}, keyword: keyword,teamId: formItem.id };
      searchDentist(parameters).then((res: { records: any; data: any[] }) => {
        if (res && res.data ) {
          let records = res.data ? res.data : [];
          const options: any[] = [];
          records.forEach((r: any) => {
            options.push({
              value: r.id,
              label: r.name,
            });
          });
          func(options);
        }
      });
    }
    const onMemberSearch = (keyword: any) => {
      let fun = (option: any) => {
        selectorOptions.memberOptions = option;
      }
      searchDentistByKeyword(keyword, fun);
    }


    const setFormItemValue = async (id: any) => {
      console.log(id);
      await getDentistTeam(id).then((data: any) => {
        formItem.id = data.id;
        let principal = data.principal;
        formItem.name = data.name;
        formItem.principal = {label: principal?principal.name:'', value: principal?principal.id:''}
        formItem.memberIds = [];
        selectorOptions.memberOptions = [];
        if (data.dentists) {
          data.dentists.forEach((x: any) => formItem.memberIds.push(x.id));
          data.dentists.forEach((x: any) => selectorOptions.memberOptions.push({
            label: x.name,
            value: x.id
          }));
        }
        console.log(formItem);

      });
    }

    watch(() => props.dentistTeamId, async (curr, origin) => {
      console.log("origin", origin)
      await setFormItemValue(curr)
    });

    onMounted(async () => {
      if (props.dentistTeamId === 0) return;
      await setFormItemValue(props.dentistTeamId)
      await onPrincipalSearch('');
      await onMemberSearch('');
    });

    return {
      ...toRefs(data),
      labelCol: {style: {width: "150px"}},
      wrapperCol: {span: 14},
      formItem: formItem,
      refDentistTeamForm: refDentistTeamForm,
      rules,
      data,
      selectorOptions,
      onPrincipalSearch,
      onMemberSearch,
      onSubmit,
      handleChange,
      handleCancel,
      STATUS,
    };


  },
  components: {},
});
